import React, { useEffect, useState } from 'react'
import {
    AiOutlineArrowUp
} from 'react-icons/ai';
import { IconContext } from "react-icons";
import '../styles/flechaSubida.scss'


const Scroll = ({
    showBelow,
}) => {

    const [show, setShow] = useState(showBelow ? false : true)

    const handleScroll = () => {
        if (window.pageYOffset > showBelow) {
            if (!show) setShow(true)
        } else {
            if (show) setShow(false)
        }
    }

    const handleClick = () => {
        window[`scrollTo`]({ top: 0, behavior: `smooth` })
    }

    useEffect(() => {
        if (showBelow) {
            window.addEventListener(`scroll`, handleScroll)
            return () => window.removeEventListener(`scroll`, handleScroll)
        }
    })

    return (
        <IconContext.Provider value={{ size: "4rem" }}>
            {show &&
                <div className="flecha-subida" role="button" tabIndex="0" onClick={handleClick} onKeyDown={handleClick} aria-label="to top">
                    <AiOutlineArrowUp />
                </div>
            }
        </IconContext.Provider>
    )
}
export default Scroll
