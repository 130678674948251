import algoliasearch from "algoliasearch/lite"
import { default as React, useState, useMemo } from "react"
import { InstantSearch } from "react-instantsearch-dom"
import CajaBuscar from "./cajaBuscar"
import SearchResult from "./resultados"
import "../../styles/ventanaBuscar.scss"



function Buscar({ indices }) {
    
    const [query, setQuery] = useState()
    // const searchClient = useMemo(
      
    //   () =>
    //     algoliasearch(
    //       process.env.GATSBY_ALGOLIA_APP_ID,
    //       process.env.GATSBY_ALGOLIA_SEARCH_KEY
    //     ),
    //   []
    // )

    const algoliaClient = algoliasearch(
      process.env.GATSBY_ALGOLIA_APP_ID,
      process.env.GATSBY_ALGOLIA_SEARCH_KEY
    );
    
    // const searchClient = {
    //   search(requests) {
    //     const newRequests = requests.map((request)=>{
          
    //       // test for empty string and change request parameter: analytics
    //       if(!request.params.query || request.params.query.length===0) {
    //         request.params.analytics=false
    //       }
    //       return request
    //     });
    //     return algoliaClient.search(newRequests);
    //   },
    // };
    const searchClient = {
      ...algoliaClient,
      search(requests) {
        if (requests.every(({ params }) => !params.query)) {
          return Promise.resolve({
            results: requests.map(() => ({
              hits: [],
              nbHits: 0,
              nbPages: 0,
              page: 0,
              processingTimeMS: 0,
            })),
          });
        }

        const newRequests = requests.map((request)=>{
            // test for empty string and change request parameter: analytics
            if(!request.params.query || request.params.query.length===0) {
              request.params.analytics=false
            }
            return request
          });
    
        return algoliaClient.search(requests);
      },
    };
    
    
    function close() {
      document.getElementById('ventana-buscar').classList.remove('activo')
    }
    return (
        <div id="ventana-buscar">
          <div role="button" tabIndex="0" aria-label="Cerrar ventana de buscar" className="cerrar-modal-buscar" onKeyDown={() => close()} onClick={() => close()}>
                    <div className="hamburguesa">
                        <div className="linea lin1"></div>
                        <div className="linea lin2"></div>
                    </div>
                </div>
          <div className="limitador">

            <InstantSearch
              searchClient={searchClient}
              indexName={indices[0].name}
              onSearchStateChange={({ query }) => setQuery(query)}
            >
              <CajaBuscar  />
              <SearchResult
                show={query && query.length > 0}
                indices={indices}
                className="resultados"
                />
            </InstantSearch>
          </div>
        </div>
    )
  }

export default Buscar