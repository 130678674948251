import React from 'react'
import { connectSearchBox } from "react-instantsearch-dom"

export default connectSearchBox(
    ({ refine, currentRefinement, className, onFocus }) => (
      <form className={className}>
        <input
          className="SearchInput"
          type="text"
          placeholder="Buscar"
          aria-label="Buscar"
          onChange={e => refine(e.target.value)}
          value={currentRefinement}
          onFocus={onFocus}
        />
      </form>
    )
  )