import React, { Component } from 'react'
import "../styles/contacto.scss"
import "../styles/newsletter.scss"
import { Link } from 'gatsby'
import addToMailchimp from 'gatsby-plugin-mailchimp'



class Newsletter extends Component {

    updateInputValue = (input, event) => {
        const newState = {};
        newState[input] = event.target.value;
        this.setState(newState);
    };

    constructor(props) {
        super(props);
        this.state = {
            nombre: '',
            apellidos: '',
            email: '',
            mensaje: '',
            respuesta: '',
            isChecked: false,
        };
    }

    _handleSubmit = e => {
        e.preventDefault()

        addToMailchimp(this.state.email, {
            FNAME: this.state.nombre,
            LNAME: this.state.apellidos,

        }, this.state)
            .then(({ msg, result }) => {
                this.setState({ respuesta: result })
                this.setState({ mensaje: msg })
                if (result !== 'success') {
                    throw msg
                }

            })
            .catch(err => {
                this.setState({ mensaje: err })
            }).then(() => {
                this.setState({ nombre: '' })
                this.setState({ apellidos: '' })
                this.setState({ email: '' })
                this.setState({ isChecked: false })
            })
    }

    cambiarCheckNews = () => {
        this.setState(prevState => ({ isChecked: !prevState.isChecked }));
    }

    close() {
        var modal = document.getElementsByClassName('modal-newsletter')[0]
        modal.classList.remove('activo');
        this.setState({ mensaje: '', respuesta: '' })
    }

    render() {
        return (
            <div className="newsletter-container limitador-inicio header-inicio">

                <div role="button" tabIndex="0" aria-label="Cerrar ventana de newsletter" className="cerrar-modal-newsletter" onKeyDown={() => this.close()} onClick={() => this.close()}>
                    <div className="hamburguesa">
                        <div className="linea lin1"></div>
                        <div className="linea lin2"></div>
                    </div>
                </div>

                <div className="detalle destacado">
                    <p>Newsletter</p>
                </div>
                <div className="titulo">
                    <h2>Apúntate a nuestra newsletter</h2>
                    <p>Suscríbete a nuestro boletín para estar al día de todas las noticias sobre nosotros y el mundo tecnológico.</p>
                    <div className="caja-formulario">
                        <form className="form-newsletter" method="post" action="#" onSubmit={this._handleSubmit}>
                            <div>
                                <label htmlFor="nombre-news">Nombre</label>
                                <input
                                    name="nombre-news"
                                    placeholder="Nombre (Opcional)"

                                    value={this.state.nombre}
                                    onChange={this.updateInputValue.bind(this, 'nombre')}
                                    style={{ fontFamily: "Now-black-light" }}
                                />
                            </div>
                            <div>
                                <label htmlFor="apellidos-news">Apellido</label>
                                <input
                                    name="apellidos-news"
                                    placeholder="Apellido (Opcional)"

                                    value={this.state.apellidos}
                                    onChange={this.updateInputValue.bind(this, 'apellidos')}
                                    style={{ fontFamily: "Now-black-light" }}
                                />
                            </div>
                            <div>
                                <label htmlFor="email-news">E-mail</label>
                                <input
                                    type="email"
                                    name="email-news"
                                    placeholder="E-mail"
                                    required
                                    value={this.state.email}
                                    onChange={this.updateInputValue.bind(this, 'email')}
                                    style={{ fontFamily: "Now-black-light" }}
                                />
                            </div>
                            <br />
                            <div className="caja-checkbox-news">
                                <input
                                    type="checkbox"
                                    id="privacidad-news"
                                    name="privacidad-news"
                                    checked={this.state.isChecked}
                                    onChange={this.cambiarCheckNews}
                                />
                                {/* <label htmlFor="privacidad-news" className="check-box-news">

                                </label> */}
                                <label htmlFor="privacidad-news" className="label-check-news">He leído y acepto la <Link className="link-privacidad-nws" to="/politica-de-privacidad/">política de privacidad</Link></label>
                            </div>

                            <div className={`mensaje-formulario
                                ${!this.state.respuesta ? "" :
                                    this.state.respuesta !== "error" ? "correcto"
                                        : "error"
                                }                              
                            `} dangerouslySetInnerHTML={{ __html: this.state.mensaje }} />

                            <div className="icono-input">
                                <input type="submit" value="Enviar" className="boton-enviar" disabled={!this.state.isChecked} />
                            </div>
                            <p><small className="texto-recaptcha newsletter">Este sitio está protegido por reCAPTCHA y se aplican la <a href="https://policies.google.com/privacy">política de privacidad</a> y los <a href="https://policies.google.com/terms">términos del servicio</a> de Google.</small></p>
                        </form>
                    </div>
                </div>

            </div>

        )
    }
}


export default Newsletter