import { Link } from "gatsby"
import { default as React } from "react"
import {
  connectStateResults,
  Highlight,
  Hits,
  Index,
  PoweredBy,
} from "react-instantsearch-dom"
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';



const HitCount = connectStateResults(({ searchResults }) => {

  const hitCount = searchResults && searchResults.nbHits

  return hitCount > 0 ? (
    <div className="HitCount">
      <p>{hitCount} resultado{hitCount !== 1 ? `s` : ``}</p>
    </div>
  )
    : (
      <div className="HitCount">
        No hay resultados para esta búsqueda
      </div>
    )
})

const PageHit = ({ hit }) => (
  <div className="resultado">
    <Link to={`${hit.slug}/`}>
      {hit.internal.type === "StrapiPostSecurity"
        ? (
          <div>
            <h4><Highlight attribute="titulo" hit={hit} tagName="mark" /></h4>
            <span className="tipo-res"> | Post</span>
          </div>
        )
        : (
          <div>
            <h4><Highlight attribute="titulo" hit={hit} tagName="mark" /></h4>
            <span className="tipo-res"> | Página</span>
          </div>
        )
      }
    </Link>
    <Highlight attribute="extracto" hit={hit} tagName="mark" />
  </div>
)

const HitsInIndex = ({ index }) => (
  <Index indexName={index.name}>
    <Hits className="Hits" hitComponent={PageHit} />
  </Index>
)

const SearchResult = connectStateResults(({ indices, className, searchState }) => {

  if (searchState && searchState.query) {
    return (
      <>
        <HitCount />
        <SimpleBar className="scroll-busqueda">
          <div className={className} id="caja-resultados">
            {indices.map(index => (
              <HitsInIndex index={index} key={index.name} />
            ))}
            <PoweredBy />
          </div>
        </SimpleBar>
      </>
    )
  } else {
    return (
      <div className={className} id="caja-resultados">
        <h3> No se ha hecho ninguna búsqueda</h3>
      </div>
    )
  }
})


export default SearchResult